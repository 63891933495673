import React, { useRef, useEffect, useState } from "react";
import logo from "../assets/images/logo24.png";
import footerLogo from "../assets/images/raedLogo.png";
export default function Layout({
  children,
  loading,
  user,
  id,
  hideKeys,
  step1,
  step2,
  step3,
  step5,
  onClick = () => {},
}) {
  const parentRef = useRef(null);
  const element1Ref = useRef(null);
  const element2Ref = useRef(null);
  const [parentHeight, setParentHeight] = useState(
    localStorage.getItem("parentHeight") || "100vh"
  );

  useEffect(() => {
    const parentElement = parentRef.current;
    const element1 = element1Ref.current;
    const element2 = element2Ref.current;

    // Calculate the height of element1 and element2
    const element1Height = element1.offsetHeight;
    const element2Height = element2.offsetHeight;

    // Get the height of the screen
    const screenHeight = window.innerHeight;

    // Calculate the total height of element1 and element2
    var totalHeight = element1Height + element2Height;
    const path = window.location.pathname;
    if (path == "/") {
      totalHeight += 200;
    }

    // Compare the total height with the height of the screen
    // If total height is greater than screen height, set parent height to 100%
    // Otherwise, set parent height to 100vh
    if (totalHeight > screenHeight) {
      setParentHeight("100%");
    } else {
      setParentHeight("100vh");
    }
    console.log(element1Height, element2Height, totalHeight, screenHeight);

    // Store the calculated height in localStorage
    localStorage.setItem("parentHeight", parentHeight);
  }, []);

  useEffect(() => {
    // If URL has value after forward slash
    const path = window.location.pathname;

    // If URL has value after forward slash, add class to div element
    if (path == "/") {
      const myDiv = document.getElementById("topBox");
      myDiv.classList.add("notValid");
    }
  }, []);
  return (
    <section
      ref={parentRef}
      style={{ height: parentHeight }}
      className="bg-[#EEEEEE] flex flex-col justify-between MainBox "
    >
      <div id="topBox" className="bg-[#F4F8FA] invitaionBox relative">
        <div
          ref={element1Ref}
          className="flex flex-col space-y-4  px-4 sm:px-5 lg:px-20 pt-5  md:pt-10 lg:pt-6 lg:10/12  mx-auto "
        >
          <div className="text-center mx-auto relative w-48 sm:w-max  pb-6 sm:pb-10  flex items-center ">
            <img width={174} src={logo} alt="applogo" />
            {/* <h1 className="top-[25%] lg:top-[30%] text-2xl md:text-3xl xl:text-5xl md:leading-[54px] xl:leading-[60px] text-[#7A7A7A] font-krona z-10 relative center">
            RAED <br /> Annual Majlis
            <span className="font-freehand text-[#108CD1]"> 23</span>
          </h1> */}

            {/* <div className="clipy bg-white h-32 md:h-40 w-40 md:w-52 xl:h-52 xl:w-64" /> */}
          </div>
          {
            //   loading ? (
            //   <Loader />
            // ) :
            !user && id ? (
              <div className="NotFoundBox">
                <div className="text-lg lg:text-xl font-regular text-center font-euclidL notFound ">
                  March 3nd, 2024 <br /> 12:00 p.m.
                </div>
                <div className="text-lg lg:text-xl leading-[63px] lg:leading-[70px] font-regular text-center font-euclidL notFound">
                  RIYADH, KSA
                </div>
              </div>
            ) : (
              <>
                <div>
                  {children}

                  {!hideKeys && (
                    <div className="flex flex-row justify-around ">
                      {step3 && user?.participated === "NO" && (
                        <div
                          className="relative md:h-12 flex items-center cursor-pointer mt-6"
                          onClick={() => !step2 && onClick("YES")}
                        >
                          <div className="border-[#009B22] border py-3 px-10  rounded-full font-euclidL">
                            <h1 className="text-md md:text-xl green relative z-10">
                              Attending
                            </h1>
                          </div>
                          {/* <div className="clipy bg-[#CBF5C7] h-5 md:h-7 w-8 md:w-11 " /> */}
                        </div>
                      )}
                      {step2 && user?.participated === "YES" && (
                        <div
                          className="relative md:h-12 flex items-center cursor-pointer mt-6"
                          onClick={() => !step3 && onClick("NO")}
                        >
                          <div className="border-[#FF004D] border px-10 py-3 rounded-full font-euclidL">
                            <h1 className="text-md md:text-xl red relative z-10">
                              Not Attending
                            </h1>
                          </div>
                          {/* <div className="clipy bg-[#FAD7D7] h-5 md:h-7 w-8 md:w-11 " /> */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )
          }
        </div>
        <div ref={element2Ref} className="bottomPart  bottom-0">
          {!!user && (
            <div className=" px-[14px] py-4 mt-[12%]">
              <p className="text-center mx-auto text-[12px] sm:text-sm leading-[24px] font-medium font-euclid text-[#4f4f4f] ">
                This invitation is non-transferable <br /> Designed for{" "}
                <strong> {user?.["Guest Name"]} </strong> from{" "}
                <strong> {user?.["Organization"]}</strong>
              </p>
            </div>
          )}

          <div className="divider"></div>
          <div className=" w-full mb-5">
            <div className="footerLogo w-fit m-auto ">
              <img src={footerLogo} alt="footer" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
